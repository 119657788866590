import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import CookieBar from "../components/cookieBar"
import ServiceMenu from "../components/services/servicesMenuHeader"

export default () => {
    return (
        <div>
            <SEO title={'404'} canonical={'https://icon-worldwide.com/404'}/>
            <CookieBar/>
            <Header/>
            <div id="mobile-menu"><ServiceMenu/></div>
            <h1>404 Error page</h1>
            <Footer/>
        </div>
    )
}